<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.edit', {name: $tc('message.calendar', 2)}) }}</h3>
    <calendarBreadcrumbs :link="'dashboardAssistentEdit'" />
    <transition name="flixFadeIn">
      <div v-if="calendar" :key="updateKey" class="flix-form-group">
        <h4 class="flix-html-h4">{{ calendar.title }}</h4>
        <div class="flix-form-group">
          <editBtn :onClick="function () { setEdit() }">
            <template v-slot:text>
              {{ $t('message.edit', { name: $tc('message.calendar', 1) }) }}
            </template>
          </editBtn>
        </div>
        <div class="flix-form-group flix-text-right">
          <router-link :to="{name: 'dashboardAssistentDelete', params: {id: calendar.ID}}" class="flix-html-a flix-text-danger" >
            <flixIcon id="bin" /> {{ calendar.title }} {{ $t('message.delete') }}
          </router-link>
        </div>
        <div class="flix-form-group flix-text-right" v-if="!$store.getters.assistents.length || $store.getters.assistents.length < variables.user.max_calendar">
          <a href="#" class="flix-html-a" @click.prevent="setClone">
            <flixIcon id="copy" /> {{ $t('message.createNew', {name: calendar.title}) }} / {{ $t('message.duplicate') }}
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    calendarBreadcrumbs () { return import('@/components/assets/calendarBreadcrumbs') }
  },
  props: {},
  data () {
    return {
      updateKey: false,
      data: false,
      presets: false,
      calendar: false,
      variables: this.$getUserVariables()
    }
  },
  watch: {
    '$route.params.id' () { this.getCalendar(); this.updateKey = new Date().getTime() }
  },
  methods: {
    setClone () {
      this.$store.dispatch('setCloneAssistent',
        {
          ID: this.calendar.ID,
          callback: function (data) {
            this.$store.dispatch('setAssistent', {
              ID: data.ID,
              data: data,
              callback: function () {
                this.$store.dispatch('setStandardReminder', {
                  ID: data.ID,
                  callback: function () {
                    var assistents = this.$store.getters.assistents
                    assistents.push(data)
                    this.$store.commit('setAssistents', assistents)
                    if (typeof data.styling !== 'undefined' && typeof data.styling.accent !== 'undefined') {
                      this.$store.commit('setAssistentColor', { ID: data.ID, color: data.styling.accent })
                      this.$store.commit('setActiveAssistent', { ID: data.ID, color: data.styling.accent, title: data.title, date: false })
                    }
                    this.$router.push({ name: 'dashboardAssistentEdit', params: { id: data.ID } })
                  }.bind(this)
                })
              }.bind(this)
            })
          }.bind(this)
        })
    },
    setEdit () {
      this.$router.push({ name: 'dashboardAssistentEdit', params: { id: this.calendar.ID } })
    },
    setSettings () {
      this.$router.push({ name: 'dashboardSettings' })
    },
    getCalendar () {
      if (this.$route.params.id === 'all' || this.$route.params.id === '') {
        return false
      }
      this.calendar = this.$store.getters.assistentsByID[this.$route.params.id]
    }
  },
  mounted () {
    this.getCalendar()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
